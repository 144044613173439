// App.js
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import './App.scss'
import Home from './Sidebar/Home/Home';
import Header from './Common/Header/Header';
import Provider from './Common/Context/Provider';
import Enquiry from './Sidebar/Enquiry/Enquiry';
import Loader from './Common/Loader/Loader';
import Sneck from './Common/Sneck/Sneck';
import Workdata from './Sidebar/Workdata/Workdata';
import Student from './Sidebar/Students/Student';
import Attendance from './Sidebar/Attendance/Attendance';
import Passport from './Sidebar/Passport/Passport';
import Aadhar from './Sidebar/Aadhar/Aadhar';
import Pan from './Sidebar/Pan/Pan';
import Voter from './Sidebar/Voter/Voter';
import Driving from './Sidebar/Driving/Driving';
import Swork from './Sidebar/Swork/Swork';
import Other from './Sidebar/Other/Other';
import Addwork from './Sidebar/Addwork/Addwork';
import Visitor from './Sidebar/VisitorVisa/Visitor';
import Oci from './Sidebar/OCI/Oci';
import Light from './Sidebar/LightBill/Light';
import Restore from './Sidebar/Restore/Restore';
import Today from './Sidebar/Today/Today';
import Rejected from './Sidebar/Rejected/Rejected';
import Gst from './Sidebar/GST/Gst';
import NewAadhar from './Sidebar/NewAadhar/NewAadhar';
import NotImp from './Sidebar/NotImp/NotImp';


const App = () => {

  return (

   <BrowserRouter>
   <Provider>
   <Loader/>
   <Sneck/>
   <div className='app-main'>
<Header/>
      <div className="flex">
    <Sidebar/>


  <div className='main'>
      <Routes>




      <Route path="/" element={<Home/>} />
      <Route path="/enquiry" element={<Enquiry/>} />
      <Route path="/restore" element={<Restore/>} />
      <Route path="/work-accept" element={<Workdata/>} />
      <Route path="/student" element={<Student/>} />
      <Route path="/attendance" element={<Attendance/>} />
      <Route path="/passport" element={<Passport/>} />
      <Route path="/visitor-visa" element={<Visitor/>} />
      <Route path="/light-bill" element={<Light/>} />
      <Route path="/today" element={<Today/>} />
      <Route path="/rejected" element={<Rejected/>} />

      <Route path="/oci" element={<Oci/>} />

      <Route path="/gst" element={<Gst/>} />
    
      <Route path="/aadhar-card" element={<Aadhar/>} />
      <Route path="/not-imp" element={<NotImp/>} />
      <Route path="/new-aadhar" element={<NewAadhar/>} />
      <Route path="/pan-card" element={<Pan/>} />
      <Route path="/voter-id" element={<Voter/>} />
      <Route path="/driving-license" element={<Driving/>} />
      <Route path="/student-work" element={<Swork/>} />
      <Route path="/other-work" element={<Other/>} />
      <Route path="/add-work" element={<Addwork/>} />
      
   
   
      
      </Routes>

      </div>
      
    </div>

    </div>

    </Provider>

    </BrowserRouter>

    

  );
}

export default App;

import React, { useContext, useEffect } from 'react'
import '../Passport/Passport.scss'
import MyContext from '../../Common/Context/MyContext'
import { RiDeleteBin6Line, RiEjectFill, RiEmotionNormalFill, RiEmotionNormalLine, RiNotionLine } from 'react-icons/ri';
import { IoSaveOutline } from 'react-icons/io5';
import { RxCross1 } from 'react-icons/rx';
import { MdEdit, MdLabelImportantOutline, MdOutlineLabelImportant } from 'react-icons/md';
import { IoMdArrowDropdown } from 'react-icons/io';
import Select from '../../Common/Select/Select';
import { FiEye } from 'react-icons/fi';
import { BiSolidShow } from 'react-icons/bi';
import { CgToday } from 'react-icons/cg';


const Light = () => {

  const { status,handleStatus, handleNimp,handleReject,drop,handleToday,important,nmi,passport,select,setSelect,handleNormal,editedData,handleSave,handleMove,setEditingId,setEditedData,editingId, fetchpassport,handleDp,handleEdit } = useContext(MyContext)

  useEffect(() => {
    fetchpassport()
  
  }, [])

  return (
    <div className="passport-table">

    
      <table>
        <thead>
          <tr>
          <th></th>
            <th>Name</th>
            <th>Date</th>
            <th>Work</th>
            <th className='select' onClick={()=>setSelect(!select)}>
            {drop} <IoMdArrowDropdown/>

            {select && <Select/>}
            </th>
           
          </tr>
        </thead>

        {passport && passport.length>0?
        <tbody>
          {passport
            .filter(a => a.important === important)
            .filter(a =>a.status === status)
            .filter(a => a.normal === nmi)
            .filter(p => p.idType==='light-bill' )
            .filter(p => p.today===false )
            .filter(a => a.restore !== true)
            .filter(a => a.rejected !== true)
            .filter(a => a.notimp !== true)
            .map((item) => (
            <tr key={item._id}>
            <td className='overlay'>

            {
              editingId !== item._id &&
              <>
            {
             important? <MdOutlineLabelImportant />:
            <MdLabelImportantOutline  onClick={() => handleMove(item._id)}/>
            }
            
            {nmi?
             <RiEmotionNormalFill/>:
            <RiEmotionNormalLine onClick={() => handleNormal(item._id)}/>
            
            }

            <RiNotionLine color='blue' onClick={() => handleNimp(item._id)}/>

            <CgToday color='green' onClick={() => handleToday(item._id)}/>

            </>

          }
            </td>
              <td> {editingId === item._id ? (
                <input
                  type="text"
                  value={editedData.name}
                  onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}
                  
                />
              ) : (
                item.name
              )}</td>

              <td>
              {editingId === item._id ? (
                <input
                  type="date"
                  value={new Date(editedData.date).toISOString().substr(0, 10)} 
                onChange={(e) => setEditedData({ ...editedData, date: e.target.value })}
                  
                />
              ) : (
                new Date(item.date).toLocaleDateString('en-GB')
              )}
             </td>

              <td>
              {editingId === item._id ? (
                <input
                  type="text"
                  value={editedData.work}
                  onChange={(e) => setEditedData({ ...editedData, work: e.target.value })}
                  
                />
              ) : (
                item.work
              )}
              </td>
              <td className='overlay'>
              {
editingId===item._id?
<>
<IoSaveOutline   onClick={handleSave}/>
<RxCross1  onClick={() => setEditingId(null)} />
</>:
                
                <>
                
              <RiDeleteBin6Line   onClick={() => handleDp(item._id)} />
              <MdEdit onClick={()=>handleEdit(item)}/>
              {status?<RiEjectFill color='red' onClick={()=>handleReject(item._id)}/>:
              <FiEye onClick={()=>handleStatus(item._id)}/>
              }
              </>
              }
              </td>
              
              
            </tr>
          ))}
        </tbody>:

        <p>No Data Available</p>

      }
      </table>
     
    </div>
  )
}

export default Light

import { BiCalendar, BiFoodTag, BiRecycle } from "react-icons/bi";
import { BsLightbulbFill, BsPassport } from "react-icons/bs";
import { GrFormAdd, GrNew } from "react-icons/gr";
import { IoIosGitNetwork } from "react-icons/io";
import { IoCarSportOutline } from "react-icons/io5";
import { LiaIdCard } from "react-icons/lia";
import { LuLayoutDashboard, LuSendHorizonal } from "react-icons/lu";
import { MdOutlinePerson } from "react-icons/md";
import { PiCardsThreeLight, PiCreditCardDuotone, PiStudent } from "react-icons/pi";
import { SiPolywork } from "react-icons/si";
import { FaIdCardAlt, FaBusinessTime } from "react-icons/fa"; // New Icons

import { IoMdPeople } from "react-icons/io"; // New Icon for 'visitor-visa'
import { CgToday } from "react-icons/cg";
import { RiEjectFill, RiNotionLine } from "react-icons/ri";

const Json = [


  {
    "id": 13,
    "icon": <GrFormAdd />,
    "route": "/add-work",
    "title": "Add Work"
  },
  {
    "id": 1,
    "icon": <LuLayoutDashboard />,
    "route": "/",
    "title": "Home"
  },

  {
    "id": 1,
    "icon": <CgToday />,
    "route": "/today",
    "title": "Today"
  },

  {
    "id": 1,
    "icon": <RiEjectFill />,
    "route": "/rejected",
    "title": "Rejected"
  },

  {
    "id": 1,
    "icon": <BiRecycle />,
    "route": "/restore",
    "title": "Restore"
  },

  {
    "id": 7,
    "icon": <RiNotionLine/>,
    "route": "/not-imp",
    "title": "Not Important"
  },
 

  {
    "id": 6,
    "icon": <BsPassport />,
    "route": "/passport",
    "title": "Passport"
  },
  {
    "id": 7,
    "icon": <PiCreditCardDuotone />,
    "route": "/aadhar-card",
    "title": "Aadhar Card"
  },

  {
    "id": 7,
    "icon": <GrNew />,
    "route": "/new-aadhar",
    "title": "New Aadhar Card"
  },


  {
    "id": 8,
    "icon": <LiaIdCard />,
    "route": "/pan-card",
    "title": "Pan Card"
  },
  {
    "id": 9,
    "icon": <PiCardsThreeLight />,
    "route": "/voter-id",
    "title": "Voter Id"
  },
  {
    "id": 10,
    "icon": <IoCarSportOutline />,
    "route": "/driving-license",
    "title": "Driving License"
  },
  {
    "id": 11,
    "icon": <SiPolywork />,
    "route": "/student-work",
    "title": "Student Work"
  },
  {
    "id": 12,
    "icon": <LuSendHorizonal />,
    "route": "/other-work",
    "title": "Other Work"
  },

  {
    "id": 14,
    "icon": <FaBusinessTime />,
    "route": "/gst",
    "title": "GST"
  },
  {
    "id": 15,
    "icon": <IoMdPeople />,
    "route": "/visitor-visa",
    "title": "Visitor Visa"
  },
  {
    "id": 16,
    "icon": <FaIdCardAlt />,
    "route": "/oci",
    "title": "OCI"
  },
  {
    "id": 17,
    "icon": <BsLightbulbFill />,
    "route": "/light-bill",
    "title": "Light Bill"
  },

  {
    "id": 2,
    "icon": <MdOutlinePerson />,
    "route": "/enquiry",
    "title": "Enquiry"
  },
  {
    "id": 3,
    "icon": <IoIosGitNetwork />,
    "route": "/work-accept",
    "title": "Work Accept"
  },
  {
    "id": 4,
    "icon": <PiStudent />,
    "route": "/student",
    "title": "Students"
  },
  {
    "id": 5,
    "icon": <BiCalendar />,
    "route": "/attendance",
    "title": "Attendance"
  },
];

export default Json;
